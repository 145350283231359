import React from 'react';
import './AccountComponent.scss';
import {ProfileService} from '../../services/ProfileService';
import {Auth0User, CustomerModel, PasswordModel, ProfileModel} from '../../services/Models';
import {HttpError} from '../../services/HttpService';
import ModalComponent from '../modal/ModalComponent';
import ProfileFormComponent from './accountForms/ProfileFormComponent';
import {codesLanguages, conf} from '../../config';
import Util from '../../util/util';
import CardComponent from '../common/CardComponent';
import PasswordFormComponent from './accountForms/PasswordFormComponent';
import AddressFormComponent from './accountForms/AddressFormComponent';
import PhoneFormComponent from './accountForms/PhoneFormComponent';
import MembershipFormComponent from './accountForms/MembershipFormComponent';
import PrivacyPolicyFormComponent from './accountForms/PrivacyPolicyFormComponent';
import {Route, RouteProps, Switch} from 'react-router';
import CloseAccountFormComponent from './accountForms/CloseAccountFormComponent';
import LoadingErringComponent from '../common/LoadingErringComponent';
import CardFieldRowComponent from '../common/CardFieldRowComponent';
import LanguageFormComponent from './accountForms/LanguageFormComponent';
import {HTTP_ERROR_CODE} from '../../services/HttpTypes';
import {Translation} from 'react-i18next';
import {TFunction} from 'i18next';
import { Auth0Service } from '../../services/Auth0Service';

type Props = {};
type State = {
  profile: ProfileModel | null;
  httpError: string;
  auth0id : string;
};
export default class AccountComponent extends React.Component<Props, State> {
  public state: State = {
    profile: null,
    httpError: '',
    auth0id: '',
  };

  public componentDidMount(): void {
    this.fetchProfile();
  }

  public render(): JSX.Element {
    if (this.state.httpError || this.state.profile === null) {
      if(this.state.httpError === 'Not Login'){
        return <React.Fragment>
          <div className="emptySpacer pt-5">
            <div id="accountComponent" className="container mt-3">
              <div className="errorComponent container mt-3 h-100" role="alert">
                <div className="row">
                  <div className="col-12">
                    <div className="alert alert-danger" role="alert"> Your session has expired or you are not logged in. Please <a href={Util.routeHashToURL(conf.hash.login)}>Sign In</a> and try again.

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      } else{
        return <LoadingErringComponent error={this.state.httpError} loading={this.state.profile === null}/>;
      }
    }

    const p: ProfileModel = this.state.profile;
    const c: CustomerModel = p.customer;

    return <Translation>{(t: TFunction): JSX.Element => {
      return <React.Fragment>
        <Switch>
          <Route path={conf.hash.accountEditProfile} render={(_: Readonly<RouteProps>): JSX.Element => {
            return <ModalComponent onClose={this.closeEdits}>
              <ProfileFormComponent onSave={this.saveProfile} profileModel={p}/>
            </ModalComponent>;
          }}/>

          <Route path={conf.hash.accountEditLanguage} render={(_: Readonly<RouteProps>): JSX.Element => {
            return <ModalComponent onClose={this.closeEdits}>
              <LanguageFormComponent onSave={this.saveProfile} profileModel={p}/>
            </ModalComponent>;
          }}/>

          <Route path={conf.hash.accountEditPassword} render={(_: Readonly<RouteProps>): JSX.Element => {
            return <ModalComponent onClose={this.closeEdits}>
              <PasswordFormComponent onSave={this.savePassword}/>
            </ModalComponent>;
          }}/>

          <Route path={conf.hash.accountEditPrivacyPolicy} render={(_: Readonly<RouteProps>): JSX.Element => {
            return <ModalComponent onClose={this.closeEdits} w770={true}>
              <PrivacyPolicyFormComponent onSave={this.saveProfile} profileModel={p}/>
            </ModalComponent>;
          }}/>

          <Route path={conf.hash.accountClose} render={(_: Readonly<RouteProps>): JSX.Element => {
            return <ModalComponent onClose={this.closeEdits}>
              <CloseAccountFormComponent onSave={this.sendAccountClose} profileModel={p}/>
            </ModalComponent>;
          }}/>

          <Route path={conf.hash.accountEditMembership} render={(_: Readonly<RouteProps>): JSX.Element => {
            return <ModalComponent onClose={this.closeEdits}>
              <MembershipFormComponent onSave={this.saveProfile} profileModel={p}/>
            </ModalComponent>;
          }}/>

          <Route path={conf.hash.accountEditAddress} render={(_: Readonly<RouteProps>): JSX.Element => {
            return <ModalComponent onClose={this.closeEdits}>
              <AddressFormComponent onSave={this.saveProfile} profileModel={p}/>
            </ModalComponent>;
          }}/>

          <Route path={conf.hash.accountEditPhone} render={(_: Readonly<RouteProps>): JSX.Element => {
            return <ModalComponent onClose={this.closeEdits}>
              <PhoneFormComponent onSave={this.saveProfile} profileModel={p}/>
            </ModalComponent>;
          }}/>

        </Switch>

        <div id="accountComponent" className="container mt-3">
          <div className="row">
            <div className="col-12 col-lg-6">
              <CardComponent title={t('Profile')}
                             editRoute={conf.hash.accountEditProfile}>

                <CardFieldRowComponent
                    title={t('Full Name')}
                    value={c.firstname + ' ' + c.lastname}
                    includeDivider/>

                {/* <CardFieldRowComponent
                    title={t('Local First Name')}
                    value={c.localFirstname}
                    includeDivider/> */}

                <CardFieldRowComponent
                    title={t('Company Name')}
                    value={c.companyName}
                    description=""
                    includeDivider/>

                <CardFieldRowComponent
                    title={t('Job Title')}
                    value={c.title}
                    description=""
                    includeDivider/>

              </CardComponent>

              <CardComponent title={t('Email')}>
                <CardFieldRowComponent
                    title={t('Email')}
                    value={c.email}
                    valueNoCaps={true}
                    description={t('EmailMessage')}
                    // description="Email domain is used to determine membership affiliation for online activities and cannot be changed. You may close this account and create a new one if you wish to receive email at a new address."
                    includeDivider
                />
              </CardComponent>

              <CardComponent title={t('Account Options')}>
                {/* <CardFieldRowComponent
                    valueJSX={<a href={Util.routeHashToURL(conf.hash.accountEditPassword)}>Update Password</a>}
                    description="Change your password."
                    includeDivider
                /> */}
                <CardFieldRowComponent
                    title={t('Language')}
                    valueJSX={
                      <p className={'text-dark text-capitalize mb-3'}>
                        {
                          // @ts-ignore
                          codesLanguages[c.language] ? codesLanguages[c.language] : c.language
                        }<br/>
                        <a href={Util.routeHashToURL(conf.hash.accountEditLanguage)}>
                          {t('Language Preference')}
                        </a>
                      </p>
                    }
                    description={t('LanguageMessage')}
                    includeDivider
                />
                <CardFieldRowComponent
                    valueJSX={<a href={Util.routeHashToURL(conf.hash.accountEditPrivacyPolicy)}>{t('Privacy Policy')}</a>}
                    description={t('Privacy Description')}
                    includeDivider
                />
                {/* <CardFieldRowComponent
                    valueJSX={<a href={Util.routeHashToURL(conf.hash.accountClose)}>Close your account</a>}
                    description="Terminate your account with Semi."
                    includeDivider
                /> */}
              </CardComponent>
            </div>

            <div className="col-12 col-lg-6">
              {/* <CardComponent title={t('Membership')}
                             editRoute={conf.hash.accountEditMembership}>

                <CardFieldRowComponent
                    title={t('Membership Type')}
                    value={c.memberType}
                    includeDivider
                />
                <CardFieldRowComponent
                    title={t('Membership Status')}
                    value={c.memberStatus}
                    description={''}
                    includeDivider
                />
                <CardFieldRowComponent
                    title={t('Member Company')}
                    value={c.memberCompanyName}
                    description={c.memberLocalCompanyName ? c.memberLocalCompanyName : ''}
                    includeDivider
                />
                <CardFieldRowComponent
                    title={t('Expiration Date')}
                    value={c.duesPaidThru ? c.duesPaidThru : ''}
                    includeDivider
                />

              </CardComponent> */}

              <CardComponent title={t('Address')}
                             editRoute={conf.hash.accountEditAddress}>
                <CardFieldRowComponent
                    title={t('Home Address')}
                    valueJSX={
                      <address>
                        {c.billAddressee} <br/>
                        {c.billAddress1} <br/>
                        {[c.billCity, c.billState, c.billCountry, c.billZipcode].join(' ')}
                      </address>
                    }
                    includeDivider
                />

              </CardComponent>

              <CardComponent title={t('Phone')}
                             editRoute={conf.hash.accountEditPhone}>
                <CardFieldRowComponent
                    title={t('Phone')}
                    value={c.phone}
                    includeDivider
                />
              </CardComponent>
            </div>
          </div>
        </div>
      </React.Fragment>;
    }}</Translation>;
  }

  private fetchProfile = (): void => {
    this.setState({profile: null, httpError: ''});

    ProfileService.getCustomer()
    .then((profile: ProfileModel): void => {
      this.setState({
        profile: profile
      });
    })
    .catch((httpError: HttpError): void => {
      if (httpError.code === HTTP_ERROR_CODE.UNAUTHORIZED) {
        // this.setState({httpError: conf.authErrMessage});
        this.setState({httpError: 'Not Login'});
      } else {
        this.setState({httpError: httpError.toString()});
      }
    });


    Auth0Service.getUser().then((auth0User: Auth0User): void => {
      this.setState({
        auth0id: auth0User.idTokenPayload['sub']
      });
    }) 
    .catch((e): void => {
      console.log(e)
    });
  };

  private saveProfile = (profileModel: ProfileModel): Promise<void> => {
    return ProfileService.updateCustomer(profileModel).then((): void => {
      this.closeEdits();
    }).catch((httpError: HttpError): Promise<void> => {
      return Promise.reject(httpError);
    });
  };

  private savePassword = (passwordModel: PasswordModel): Promise<void> => {
    console.log(this.state.auth0id)
    let auth0Id = this.state.auth0id;
    return ProfileService.updatePassword(passwordModel,auth0Id).then((): void => {
      this.closeEdits();
    }).catch((httpError: HttpError): Promise<void> => {
      return Promise.reject(httpError);
    });
  };

  private sendAccountClose = (_: ProfileModel): Promise<void> => {
      return ProfileService.deleteCustomer().then((): void => {
        this.closeEdits();
      }).catch((httpError: HttpError): Promise<void> => {
        return Promise.reject(httpError);
      });
  };

  private closeEdits = (): void => {
    this.fetchProfile();
    Util.navToRoute(conf.hash.account);
  };
}
